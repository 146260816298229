jQuery( document ).ready(function($) {
  
  const openPopup = (e) => {
    e.preventDefault()
    $('.popup').addClass('active')
  }
  
  const closePopup = (e) => {
    e.preventDefault()
    $('.popup').removeClass('active')
  }
  
  $('.tour-trigger').click(openPopup)
  $('.close').click(closePopup)


});